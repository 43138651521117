<script lang="ts" setup>
type ColorMode = 'light' | 'dark';
const modes = ['light', 'dark'];
const colorMode = useColorMode();

const cycleColorMode = () => {
    const curr = modes.indexOf(colorMode.value);
    const next = (curr + 1) % modes.length;
    colorMode.value = modes[next] as ColorMode;
};

const icon = computed(() => {
    switch (colorMode.value) {
        case 'auto':
            return 'prime:desktop';
        case 'dark':
            return 'prime:moon';
        case 'light':
            return 'prime:sun';
        default:
            return 'prime:sun';
    }
});
</script>

<template>
    <button
        type="button"
        class="rounded-full p-1.5 w-9 h-9 hover:bg-neutral-200 dark:hover:bg-neutral-700"
        @click="cycleColorMode"
    >
        <Icon :name="icon" class="text-neutral-400 w-6 h-6" />
    </button>
</template>
